@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$robot: 'Roboto', sans-serif;
$main-color: #288fd8;
$main-subcolor: #f0f6f9;
$main-light-dark: rgb(158, 158, 158);
$main-light-gray: #d6e1e4;
$text: rgb(87, 87, 87);
$text-dark: rgb(45, 50, 68);
$red: #9f1e1e;
$green: #8aa93c;
$blue-black: #3b4256;