@import 'mixins';
.input-error{
    box-shadow: 0px 0px 3px 0px $red;
}
.common-section{
    border-radius: 10px;
    width: 100%;
    // height: 3000px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1360px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        position: relative;
        @include flexRow(space-between);
    }

    &__sections-container{
        max-width: 970px;
        width: 100%;
        height: 100%;
        // background-color: pink;
        @include flexColumn(flex-start);
    }

    &__asside-container{
        width: 320px;
        height: 100%;
        // background-color: pink;
        @include flexColumn(flex-start);
    }
}

.sale-section{
    border-radius: 10px;
    width: calc(100% - 60px);
    height: 360px;
    padding: 30px;
    // background-color: plum;
    background-color: white;
    margin-top: 10px;
    box-shadow: 0 0 40px 10px transparentize($text-dark, 0.9);
    
    &__form{
        width: 100%;
        height: 100%;
        // background-color: orange;
        @include flexColumn(flex-start);
    }
    
    &__form-container{
        @include flexColumn(flex-start);
        // @include flexColumn(flex-start);
        align-items: flex-start;
    }

    &__header{
        // width: 100%;
        // text-align: center;
        background-color: $red;
        font-size: 16px;
        font-weight: 900;
        color: white;
        border-radius: 10px;
        padding: 10px;
        // background-color: aqua;
        text-align: start;
    }

    &__text{
        font-size: 14px;
        font-weight: 500;
        color: $text;
        margin-top: 15px;
    }

    &__input-container{
        width: 100%;
        height: 75px;
        // background-color: orange;
        margin-top: 15px;
    }

    &__input-name{
        font-size: 14px;
        font-weight: 700;
        color: $text-dark;
    }

    &__input{
        margin-top: 8px;
        height: 48px;
        width: 218px;
        // background-color: red;
        padding: 0px 20px;
        border: 1px solid $main-light-gray;
        outline: none;
        font-size: 14px;
        font-weight: 700;
        transition: 0.3s;
        &:focus{
            // border-color: $red;
            box-shadow: 0px 0px 10px 0px $main-light-gray;
        }
    }

    &__button{
        margin-top: 15px;
        width: 175px;
        height: 50px;
        border: none;
        color: white;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        @include flexColumn(center);
        background-color: $red;
        cursor: pointer;
        border-radius: 10px;
        transition: 0.3s;
        &:hover{
            background-color: darken($red, 5%);
        }
    }

    &__policy{
        width: 100%;
        height: 20px;
        // background-color: orange;
        margin-top: 5px;
        @include flexRow(flex-start);
        align-items: center;
    }

    &__policy-text{
        font-size: 11px;
        font-weight: 500;
        color: black;
    }

    &__policy-link{
        font-size: 11px;
        font-weight: 500;
        color: $main-color;
        margin-left: 10px;
        text-decoration: none;
        transition: 0.3s;
        &:hover{
            color: $red;
        }
    }

    &__form-error{
        width: 100%;
        height: 200px;
        margin-top: 40px;
        // background-color: orange;
        text-align: center;
        @include flexColumn(center);
        font-size: 20px;
        font-weight: 500;
        color: black;
        display: none;
    }
}

.brands-section{
    border-radius: 10px;
    width: calc(100% - 60px);
    height: 580px;
    padding: 30px;
    // background-color: plum;
    background-color: white;
    margin-top: 40px;
    @include flexColumn(flex-start);
    align-items: flex-start;
    box-shadow: 0 0 40px 10px transparentize($text-dark, 0.9);

    &__header{
        // background-color: orange;
        font-size: 16px;
        font-weight: 900;
        color: white;
        background-color: $green;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        // text-align: start;
        // height: 40px;
    }

    &__brands-container{
        width: 100%;
        max-height: 500px;
        // height: 500px;
        overflow-y: scroll;
        // background-color: orange;
        margin-top: 20px;
        @include flexRow(center);
        align-items: flex-start;
        flex-wrap: wrap;
        list-style: none;

        &::-webkit-scrollbar{
            width: 2px;
        }

        &::-webkit-scrollbar-thumb{
            background-color: $main-color;
        }
    }

    &__brand{
        width: 76px;
        height: 50px;
        // background-color: aqua;
        margin: 3px;
        @include flexRow(center);
        align-items: center;
        border: 1px solid $main-light-gray;
        border-radius: 10px;
        transition: 0.3s;
        &:hover{
            border-color: $red;
        }
    }

    &__img{
        max-width: 90%;
        max-height: 90%;
    }
}

.ask-section{
    border-radius: 10px;
    width: calc(100% - 60px);
    height: 160px;
    padding: 30px 30px 70px 30px;
    background-color: white;
    // background-color: plum;
    margin-top: 40px;
    box-shadow: 0 0 40px 10px transparentize($text-dark, 0.9);
    @include flexColumn(flex-start);
    align-items: flex-start;
    position: relative;

    &__header{
        font-size: 16px;
        font-weight: 900;
        color: white;
        padding: 10px;
        background-color: $green;
        border-radius: 10px;
        // background-color: orange;
        // width: 100%;
        // text-align: start;
        height: 40px;
        // width: unset;
    }

    &__link{
        position: absolute;
        font-size: 14px;
        font-weight: 500;
        color: $text;
        margin-top: 15px;
        text-decoration: none;
        z-index: 1;
        width: calc(100% - 65px);
        height: 100%;
        top: -15px;
        left: 0px;
        padding: 0px 30px 0px 35px;
        transition: 0.3s;
        // background-color: orange;
        &:hover{
            color: $red;
        }
    }

    &__img{
        position: absolute;
        top: calc(100% - 158px);
        left: calc(100% - 232px);
    }
}

.news-section{
    width: calc(100% - 60px);
    height: 360px;
    // background-color: plum;
    background-color: white;
    padding: 30px;
    margin-top: 40px;
    border-radius: 10px;
    box-shadow: 0 0 40px 10px transparentize($text-dark, 0.9);
    @include flexColumn(flex-start);
    align-items: flex-start;

    &__header{
        font-size: 16px;
        font-weight: 900;
        color: white;
        padding: 10px;
        background-color: $green;
        border-radius: 10px;
        // background-color: orange;
        text-align: start;
        height: 20px;
    }

    &__news{
        width: calc(100% - 20px);
        padding: 10px;
        max-height: 80px;
        // min-height: 100px;
        // background-color: orange;
        border-radius: 10px;
        background-color: darken(white, 5%);
        text-decoration: none;
        margin-top: 20px;
        @include flexColumn(flex-start);
        &:nth-child(2){
            // padding: 0px 0px 20px 0px;
            border-bottom: 2px solid $main-light-gray;
        }
    }

    &__date{
        @include flexRow(flex-start);
        // background-color: aqua;
        font-size: 12px;
        font-weight: 500;
        color: black;
        height: 15px;
    }

    &__month{
        margin: 0px 4px;
    }

    &__text{
        font-size: 14px;
        font-weight: 500;
        height: 53px;
        color: $text;
        margin-top: 5px;
        overflow: hidden;
    }

    &__button{
        width: 130px;
        height: 40px;
        background-color: darken($text-dark, 15%);
        color: white;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        @include flexColumn(center);
        text-decoration: none;
        margin-top: 25px;
        border-radius: 10px;
        transition: 0.3s;
        &:hover{
            background-color: transparentize($text-dark, 0.2);
        }
    }
}


.questions-section{
    border-radius: 10px;
    width: calc(100% - 60px);
    height: 360px;
    // background-color: plum;
    background-color: white;
    padding: 30px;
    margin-top: 40px;
    box-shadow: 0 0 40px 10px transparentize($text-dark, 0.9);
    @include flexColumn(flex-start);
    align-items: flex-start;

    &__header{
        padding: 10px;
        font-size: 16px;
        font-weight: 900;
        color: white;
        border-radius: 10px;
        // background-color: orange;
        background-color: $green;
        text-align: start;
        height: 20px;
    }

    &__question{
        width: calc(100% - 20px);
        padding: 10px;
        max-height: 80px;
        // min-height: 100px;
        // background-color: orange;
        border-radius: 10px;
        background-color: darken(white, 5%);
        text-decoration: none;
        margin-top: 10px;
        @include flexColumn(flex-start);
        &:nth-child(2){
            margin-top: 20px;
            // padding: 0px 0px 20px 0px;
            border-bottom: 2px solid $main-light-gray;
        }
    }

    &__author-name{
        color: $green;
        font-size: 16px;
        font-weight: 700;
    }
    
    &__text{
        font-size: 14px;
        font-weight: 500;
        height: 70px;
        color: $text;
        margin-top: 5px;
        overflow: hidden;
    }

    &__button{
        width: 130px;
        height: 40px;
        background-color: darken($text-dark, 15%);
        color: white;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        @include flexColumn(center);
        text-decoration: none;
        margin-top: 25px;
        border-radius: 10px;
        transition: 0.3s;
        &:hover{
            background-color: transparentize($text-dark, 0.2);
        }
    }
}

@media (max-width: 1360px){
    .common-section{
        @include flexColumn(flex-start);
        // background-color: red;

        &__container{
            max-width: unset;
            width: 100%;
            // background-color: pink;
            @include flexColumn(flex-start);
        }

        &__sections-container{
            max-width: unset;
            width: 100%;
        }
    }

    .sale-section{
        display: none;
    }

    .brands-section{
        // display: none;
    }

    .asside-brands{
        display: none;
    }

    .ask-section{
        display: none;
    }

    .asside-news{
        display: none;
    }

    .asside-questions{
        display: none;
    }
}
